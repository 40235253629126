// import ZbPasswordComponent from './password-modal'
import { ServiceFactory } from '@/services/ServiceFactory'
const _barMembersService = ServiceFactory.get('BarMembersService')
export default {
  name: 'ZbTabBarTeamComponent',
  data () {
    return {
      loadingTable: true,
      members: [],
      headers: [
        {
          text: 'icon',
          value: 'id',
          sortable: false
        },
        {
          text: 'Nombre',
          value: 'name',
          sortable: false
        }
        // {
        //   text: 'Estado',
        //   value: 'state',
        //   sortable: false
        // },
        // {
        //   text: this.$t('general.delete'),
        //   value: 'action',
        //   sortable: false
        // }
      ],
      memberState: false,
      page: 1
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        try {
          // this.barData = await _barsService.show(this.$route.params.id)
          this.members = await _barMembersService.index(this.$route.params.id)
          this.loadingTable = false
          // console.log(this.members)
        } catch (error) {
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    },
    editMember (userId) {
      // console.log(id)
      this.$router.push({ name: 'member-form', params: { type: 'editMember', userId, id: this.$route.params.id } })
    },
    async editState (id, itemState) {
      this.members.active = !this.members.active
      // console.log(id, itemState)
      this.memberState = {
        active: itemState
      }
      try {
        const memberStateService = await _barMembersService.editState(id, this.memberState)
        // console.log(this.memberState)
        // console.log(memberStateService)
        if (this.members.active === false) {
          this.$swal(
            'Miembro habilitado',
            'Se modificó correctamente',
            'success'
          ).then(async (result) => {
            // this.initialize()
          })
        } else {
          this.$swal(
            'Miembro inhabilitado',
            'Se modificó correctamente',
            'success'
          ).then(async (result) => {
            // this.initialize()
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    destroyCategory (id) {
      this.$swal({
        title: '¿Está seguro de eliminar la categoria?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // await _newsCategoriesService.destroy(id)
            this.$swal(
              'Categoría eliminada',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    goNewForm (id, payload = null) {
      // console.log(id)
      this.$router.push({ name: 'member-form', params: { type: 'addMember', id: this.$route.params.id } })
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
